.mbsc-calendar-labels {
  margin-top: 2px;
}

.mbsc-calendar-day .mbsc-calendar-day-inner .mbsc-calendar-text {
  height: 60px;
  margin: 0 2px 2px 2px;
}

.custom-event {
  height: 60px;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
}

.custom-event-popup {
  height: 62px;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 3px;
  box-sizing: border-box;
}

.custom-event-mobile {
  height: 40px;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
}